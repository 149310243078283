import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="text-center mx-[0.3rem] w-full pb-12 bg-white">
      <div className="flex justify-center mb-12">
        <img
          src="/assets/images/logo noir horiz.png"
          alt="logo Dr. Youssef Gam"
          className="h-16 object-contain"
        />
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="py-12 sm:flex">
        <h5 className="text-2xl flex-auto sm:text-xl">7 RUE DE GAMMARTH</h5>
        <h5 className="text-2xl flex-auto sm:text-xl">1000 TUNIS</h5>
        <h5 className="text-2xl flex-auto sm:text-xl">TUNISIE</h5>
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="py-12 sm:flex">
        <div className="flex-auto mb-8 sm:mb-0 sm:mr-8">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            CHIRURGIE ESTHÉTIQUE
          </h5>
          <Link to="visage" className="block text-lg sm:text-base pb-3">
            Visage
          </Link>
          <Link to="seins" className="block text-lg sm:text-base pb-3">
            Seins
          </Link>
          <Link to="corps" className="block text-lg sm:text-base">
            Corps
          </Link>
        </div>
        <div className="flex-auto mb-8 sm:mb-0 sm:mr-8">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            MÉDECINE ESTHÉTIQUE
          </h5>
          <span className="block text-lg sm:text-base pb-3">
            Acide Hyaluronique
          </span>
          <span className="block text-lg sm:text-base pb-3">
            Toxine Botulique
          </span>
          <span className="block text-lg sm:text-base pb-3">Peeling</span>
          <span className="block text-lg sm:text-base">Mésothérapie</span>
        </div>
        <div className="flex-auto">
          <h5 className="text-lg font-primaryMedium pb-3 sm:text-base">
            CABINET
          </h5>
          <Link to="actualites" className="block text-lg sm:text-base pb-3">
            Actualités
          </Link>
          <Link to="contact" className="block text-lg sm:text-base">
            Contact
          </Link>
        </div>
      </div>
      <Divider
        variant="middle"
        sx={{ bgcolor: "black", width: "90%", margin: "auto" }}
      />

      <div className="pt-12 sm:flex">
        <h5 className="text-l flex-auto sm:text-base mb-4 sm:mb-0">
          © 2024 DR. YOUSSEF GAM
        </h5>

        <h5 className="text-l flex-auto sm:text-base">
          <Link to="https://www.codini.tn" target="_blank">
            CRÉATION: CODINI
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Footer;
