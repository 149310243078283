import { ComponentType } from "react";
import "./transition.css";

interface PageTransitionProps {
  OgComponent?: ComponentType;
}

function PageTransition({ OgComponent }: PageTransitionProps) {
  return (
    <div className="fixed bg-transparent top-0 left-0 w-dvw h-dvh z-50">
      <div className="relative w-full h-full grid place-items-center">
        <div className="bg-darkBg h-full w-full to-top"></div>
        {/* TOOD: animate the text to exit with the wrapper */}
        <div className="text-container">
          <div className="text-6xl text-lightB z-50 to-top-text">Dr. Youssef Gam</div>
        </div>
      </div>
    </div>
  );
}

export default PageTransition;