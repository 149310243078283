import ResponsiveAppBar from "../components/NavBar";
import { Outlet, useLocation } from "react-router-dom";
import PageTransition from "../components/PageTransition";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";

const PageContainer = () => {
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 1800);
  }, [location.pathname]);

  return (
    <>
      {isTransitioning && <PageTransition />}
      <ResponsiveAppBar />
      <Outlet key={location.pathname} />
      <Footer />
    </>
  );
};

export default PageContainer;
