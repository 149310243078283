import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { ReactElement } from 'react';

interface ProtectedRouteProps {
    element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <>{element}</>;
    } else {
        return <Navigate to="/login" />;
    }
};

export default ProtectedRoute;
