import React, { useState, useEffect } from "react";
import { Sparkles, Award, Heart } from "lucide-react";
import { motion } from "framer-motion";

interface StatItemProps {
  endNumber: string;
  label: string;
}

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className = "" }) => (
  <div
    className={`w-full hover:bg-gray-50 transition-colors duration-300 ${className}`}
  >
    {children}
  </div>
);

const StatItem: React.FC<StatItemProps> = ({ endNumber, label }) => {
  const [count, setCount] = useState(0);
  const numericEnd = parseInt(endNumber.replace(/\D/g, ""));

  useEffect(() => {
    const duration = 2000;
    const steps = 60;
    const stepValue = numericEnd / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += stepValue;
      if (current >= numericEnd) {
        setCount(numericEnd);
        clearInterval(timer);
      } else {
        setCount(Math.floor(current));
      }
    }, duration / steps);

    return () => clearInterval(timer);
  }, [numericEnd]);

  return (
    <motion.div
      className="text-center"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.4 }}
    >
      <p className="text-3xl font-light mb-2">
        {count}
        {endNumber.includes("+") ? "+" : ""}
        {endNumber.includes("%") ? "%" : ""}
      </p>
      <p className="text-sm uppercase tracking-wider">{label}</p>
    </motion.div>
  );
};

const CardContent: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => (
  <div className={`w-full ${className}`}>{children}</div>
);

const DoctorIntro = () => {
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-16 items-center">
          {/* Image Column */}
          <div className="relative">
            <motion.div
              className="aspect-[3/4] rounded-none overflow-hidden"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <img
                src="/assets/images/youssef-avatar.jpg"
                alt="Dr. Youssef Gam"
                className="w-full h-full object-cover"
              />
            </motion.div>
          </div>

          {/* Content Column */}
          <div className="space-y-12 relative">
            <motion.div
              className="text-center md:text-left"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <motion.h1 className="text-5xl font-extralight mb-6">
                Dr. Youssef Gam
              </motion.h1>
              <motion.p className="text-lg text-gray-600 leading-relaxed font-light">
                Artiste de la beauté et expert en chirurgie esthétique, le Dr.
                Gam combine précision chirurgicale et vision artistique pour
                révéler la plus belle version de vous-même.
              </motion.p>
            </motion.div>

            <motion.div
              className="grid grid-cols-3 gap-8 py-8 border-y border-gray-200"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <StatItem endNumber="15+" label="années d'expérience" />
              <StatItem endNumber="5000+" label="procédures réalisées" />
              <StatItem endNumber="98" label="taux de satisfaction" />
            </motion.div>

            <motion.div
              className="space-y-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Card>
                <CardContent className="flex items-start space-x-6 p-4 group">
                  <Sparkles className="w-6 h-6 text-gray-400 group-hover:text-gray-600 transition-colors duration-300" />
                  <div>
                    <h3 className="font-light text-xl mb-2">Expertise</h3>
                    <p className="text-gray-600 font-light">
                      Rhinoplastie, lifting facial, augmentation mammaire,
                      liposculpture
                    </p>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="flex items-start space-x-6 p-4 group">
                  <Award className="w-6 h-6 text-gray-400 group-hover:text-gray-600 transition-colors duration-300" />
                  <div>
                    <h3 className="font-light text-xl mb-2">Formation</h3>
                    <p className="text-gray-600 font-light">
                      Diplômé en chirurgie plastique, reconstructive et
                      esthétique
                    </p>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardContent className="flex items-start space-x-6 p-4 group">
                  <Heart className="w-6 h-6 text-gray-400 group-hover:text-gray-600 transition-colors duration-300" />
                  <div>
                    <h3 className="font-light text-xl mb-2">Philosophie</h3>
                    <p className="text-gray-600 font-light">
                      Une approche personnalisée qui respecte votre beauté
                      naturelle
                    </p>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoctorIntro;
