import HeroSection from "../../components/HeroSection";
import DoctorIntro from "../../components/DrGamIntro";
import MedicineSection from "../../components/MedicineSection";
import Questions from "../../components/Questions";

const HomePage: React.FC<{}> = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-10">
        <HeroSection />
      </div>
      <MedicineSection />
      <DoctorIntro />
      <div className="mt-[10rem]">
        <Questions />
      </div>
    </>
  );
};
export default HomePage;
