import ImageBannerAnimation from "./ImageBannerAnimation";

const CompositionSection: React.FC = () => {
  return (
    <section className="bg-darkBg text-lightB w-full grid place-items-center pt-24 sm:pt-24">
      <h2 className="text-center text-3xl md:text-6xl sm:text-6xl animate-slidein600 uppercase px-4 sm:px-0">
        COMPOSITION
      </h2>
      <div className="grid place-items-center gap-10 w-[90vw] sm:max-w-[70vw]">
        <div className="items-center flex flex-col lg:flex-row pt-12 sm:pt-24 w-full">
          {/* image start */}
          <div className="flex-1 overflow-hidden block w-full  px-4 lg:px-0">
            <div className="z-1 max-h-full h-full max-w-96">
              <ImageBannerAnimation imageUrl="https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-composition-top.jpg" />
            </div>
          </div>
          {/* image end */}

          {/* text start */}
          <div className="flex-1 relative h-auto lg:h-96 mt-12 lg:mt-0">
            <div className="lg:absolute lg:-left-56 w-full lg:w-[50vw] z-2 px-4 lg:px-0">
              <div className="flex flex-col gap-10 items-center">
                <div className="flex flex-col lg:flex-row gap-5 justify-normal items-start w-full">
                  <div className="hidden lg:flex items-center justify-normal gap-3 flex-1 w-full mt-3">
                    <div className="rounded-full bg-lightB p-[3px]"></div>
                    <div className="border-b border-light w-28"></div>
                  </div>
                  <p className="font-textDescription leading-8 text-base sm:text-lg">
                    L'acide hyaluronique est la molécule idéale pour le comblement
                    des rides car composée de sucre; il est naturellement présent
                    dans l'épiderme. Avec le temps, la quantité d'acide
                    hyaluronique diminue et la peau se déshydrate. Cette
                    diminution est l'un des facteurs du vieillissement cutané.
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-5 justify-normal items-start w-full mt-4 lg:mt-8">
                  <div className="hidden lg:flex items-center justify-normal mt-3">
                    <div className="rounded-full bg-lightB p-[3px] mr-[1rem] mx-[-8rem]"></div>
                    <div className="border-b border-lightB w-[20rem]"></div>
                  </div>
                  <p className="font-textDescription leading-8 text-base sm:text-lg">
                    L'acide hyaluronique est d'origine non-animale
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-5 justify-normal items-start w-full mt-4 lg:mt-8">
                  <div className="hidden lg:flex items-center justify-normal mt-3">
                    <div className="rounded-full bg-lightB p-[3px] mr-[1rem] mx-[-3rem]"></div>
                    <div className="border-b border-lightB w-[20rem]"></div>
                  </div>
                  <p className="font-textDescription leading-8 text-base sm:text-lg">
                    L'acide hyaluronique est biodégradable et résorbable.<br></br>
                    Sa dégradation par les hyaluronidase naturelles est progressive.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* text end */}
        </div>
        <div className="justify-center items-center max-w-4xl w-full px-4 lg:px-0">
          <p className="font-textDescription pb-8 sm:pb-16 text-base sm:text-lg">
            L'acide hyaluronique est disponible dans de nombreuses fluidités qui
            permettent de restaurer les volumes du visage mais également de
            combler tous les types de rides du visage, qu'elles soient fines ou
            profondes.
          </p>
          <div className="z-1 w-full sm:max-w-[48vw]">
            <ImageBannerAnimation imageUrl="https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-composition-bottom.jpg" />
          </div>
          <p className="font-textDescription py-8 sm:py-16 text-base sm:text-lg">
            Il contient de l'anesthésique local qui permet de rendre l'injection
            indolore
          </p>
        </div>
      </div>
    </section>
  );
};

export default CompositionSection;
