import * as yup from 'yup';

export const ContactSchema = yup.object().shape({
    name: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required(),
    prenom: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required(),
    email: yup.string().email().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).required("Please enter valid email"),
    phoneNumber: yup.string().required('A phone number is required'),
    sujet: yup.string().required(),
    message: yup.string().required(),
    consent: yup.boolean()
      .oneOf([true], 'You must accept the terms and conditions')
      .required('You must accept the terms and conditions'),
  });

