import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const SandClock = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  // Transform scroll progress to rotation and sand levels
  // Now rotates from upright (0) to upside-down (180) as you scroll down
  const rotation = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [180, 0, 0, 0]
  );

  // Reverse the sand scale transformations
  const topSandScale = useTransform(
    scrollYProgress,
    [0.9, 0.1],
    [0, 1] // Empty at start, fills up when rotated back
  );

  const bottomSandScale = useTransform(
    scrollYProgress,
    [0.2, 0.8],
    [0.2, 1] // Full at start, empties as you scroll
  );

  return (
    <motion.div
      ref={ref}
      className="w-32 h-48"
      initial={{ opacity: 1, x: 50 }}
      transition={{ duration: 3, delay: 9 }}
      style={{ rotate: rotation }}
    >
      <svg viewBox="0 0 120 180" className="w-full h-full">
        {/* Glass reflections */}
        <path
          d="M35,15 L85,15"
          stroke="rgba(255,255,255,0.5)"
          strokeWidth="1"
          className="mix-blend-overlay"
        />
        <path
          d="M35,165 L85,165"
          stroke="rgba(255,255,255,0.5)"
          strokeWidth="1"
          className="mix-blend-overlay"
        />

        {/* Sand in top chamber */}
        <motion.g
          style={{ originX: "60px", originY: "90px", scaleY: topSandScale }}
        >
          <path
            d="M35,15 Q60,15 85,15 L85,55 Q60,85 60,85 Q60,85 60,85 Q60,85 35,55 L35,15"
            fill="rgba(0,0,0,.1)"
          />
          {/* Sand particles */}
          <circle cx="60" cy="50" r="1" fill="rgba(0,0,0,0.2)" />
          <circle cx="55" cy="45" r="1" fill="rgba(0,0,0,0.2)" />
          <circle cx="65" cy="55" r="1" fill="rgba(0,0,0,0.2)" />
        </motion.g>

        {/* Sand in bottom chamber */}
        <motion.g
          style={{ originX: "60px", originY: "90px", scaleY: bottomSandScale }}
        >
          <path
            d="M35,165 Q60,165 85,165 L85,125 Q60,95 60,95 Q60,95 60,95 Q60,95 35,125 L35,165"
            fill="rgba(0,0,0,.1)"
          />
          {/* Sand particles */}
          <circle cx="60" cy="130" r="1" fill="rgba(0,0,0,0.2)" />
          <circle cx="55" cy="135" r="1" fill="rgba(0,0,0,0.2)" />
          <circle cx="65" cy="140" r="1" fill="rgba(0,0,0,0.2)" />
        </motion.g>

        {/* Center pinch */}
        <circle cx="60" cy="90" r="2" fill="rgba(0,0,0,0.1)" />
      </svg>
    </motion.div>
  );
};

export default SandClock;
