import { ContactForm } from "../../components/ContactForm";
import "../../App.css"

const ContactPage: React.FC = () => {
  return (
    <div className="page-animation">
      <ContactForm />
    </div>
  );
};

export default ContactPage;