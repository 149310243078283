import MedecineEsthetiqueSection from "../../components/MedecineEsthetiqueSection";
import CompositionSection from "../../components/CompositionSection";
import CeQuilFautSavoirSection from "../../components/CeQuilFautSavoirSection";
import Questions from "../../components/Questions";


const AcideHyaluroniqueComponent = () => (
  <div className="page-animation">
    <MedecineEsthetiqueSection />
    <CompositionSection />
    <div className="px-24 pb-12 mb-64">
      <h2 className="text-4xl sm:text-6xl py-12 animate-slidein600">
        CE QU'IL FAUT SAVOIR
      </h2>
      <CeQuilFautSavoirSection
        iconColor="black"
        inputControl="white"
        light={false}
        contentList={[
          {
            title: "Comment la toxine botulique fonctionne-t-elle ?",
            description:
              "La toxine botulique agit au niveau de l’union entre le nerf, qui transmet l’impulsion électrique, et le muscle qui exécute l’action, comme un isolant, entraînant une diminution localisée, voire une paralysie, de l’activité musculaire. Cette action atténue les rides placées en regard des muscles concernés. C’est un traitement temporaire, les muscles traités commencent à reprendre progressivement leur capacité contractile après quelques mois (en moyenne 4 à 6 mois).",
          },
          {
            title: "Injections de Toxine Botulique - LE BOTOX ®",
            description:
              "Liquide, la toxine botulique est injectée avec des aiguilles très fines qui rendent cette procédure presque indolore. Cette procédure dont la durée est de 15 à 30 minutes, est réalisée au cabinet médical du Dr Trevidic. Son résultat apparaît en 2 à 15 jours et c’est pour cette raison qu’une visite de contrôle est prévue 3 semaines après l’injection. A l’occasion de cette visite de contrôle, on peut procéder à une retouche si le résultat est considéré comme insuffisant et dans quelques cas asymétriques. Les doses adaptées à chaque cas permettent d’éviter l’aspect «figé» souvent redouté par les patients. Une telle utilisation permet de lisser les reliefs cutanés, d’obtenir un effet de rajeunissement par diminution de la tension musculaire. Il y a un effet préventif et protecteur contre le vieillissement quand le traitement est entretenu sur le long terme. Il a aussi un effet sur le regard des autres car il élimine les expressions négatives involontaires du visage comme l’agressivité. Indications la toxine botulique permet d’atténuer les «rides du lion», les rides du front, les rides de «la patte d’oie», les plis d’amertume, les rides péribuccales.elle agit sur l’équilibre des muscles du visage : la toxine botulique peut, par exemple, être utilisée pour relever des sourcils qui se sont abaissés au fil des ans mais aussi relâcher les muscles du cou qui créent les cordes cervicales.elle peut aussi atténuer ou corriger une asymétrie du visage.la toxine botulique permet aussi de traiter le bruxisme et l’hyperhidrose (sudation) des aisselles, des mains et des pieds.*Botox® est une marque de la société américaine ALLERGAN Inc.",
          },
          {
            title: "Suites opératoires",
            description:
              "Quelques petites rougeurs et gonflements peuvent persister 20 à 30 minutes après les injections. Les patients peuvent reprendre leurs activités normalement après la séance. Pendant les 24 heures qui suivent l’injection de toxine botulique, il faudra s’abstenir de faire du sport ou tout effort violent et éviter l’exposition des zones traitées à des fortes chaleurs (soleil, sauna, hammam).Éviter les manipulations du visage et massages ainsi que le port de casquette, casque, chapeau trop serré ou lunettes de piscine.* Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après",
          },
          {
            title: "Durée",
            description:
              "Le rétablissement complet de la contractilité musculaire intervient normalement en l’espace de 4 à 6 mois. Pour obtenir à nouveau la mise au repos des muscles, il faut renouveler l’injection.",
          },
          {
            title: "Effets indésirables et possibles complications",
            description:
              "Il ne faut pas surévaluer les risques qui demeurent minimes après injection de toxine botulique. Douleur, rougeurs, ou ecchymoses au point d’injection.Sensation de tension, de fixité du front, de la bouche ou du cou, suivant les zones injectées qui peut persister plusieurs jours.La migration du produit, produisant sa diffusion dans d’autres muscles, est très rare, mais elle peut provoquer des effets indésirables temporaires comme un relâchement de la paupière supérieure ou des sourcils.",
          },
        ]}
      />
    </div>
    <Questions />
  </div>
);



export default AcideHyaluroniqueComponent;
