import { useState, useEffect } from "react";
import Questions from "../../components/Questions";
import CeQuilFautSavoirSection from "../../components/CeQuilFautSavoirSection";

const Corps: React.FC = () => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [clickedItem, setClickedItem] = useState<string>("Ventre");
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const [contentList, setContentList] = useState<{ title: string; description: string }[]>([]);
    const [mainDescription, setMainDescription] = useState<string>("");
    const [componentKey, setComponentKey] = useState<number>(0);

    const handleClick = (item: string) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setClickedItem(item === clickedItem ? 'Ventre' : item);
            setComponentKey(prevKey => prevKey + 1);
            setIsTransitioning(false);
        }, 300);
    };

    const paragraphContent: { [key: string]: { mainDescription: string; content: { title: string; description: string }[] } } = {
        "Ventre": {
            mainDescription: "Il peut être traité par une lipoaspiration simple ou par une plastie abdominale. La plastie abdominale permet d’améliorer un abdomen relâché afin de retrouver une silhouette harmonieuse. Le geste chirurgical consiste à supprimer l’excédent de peau et de graisse mais aussi à retendre la paroi musculaire.",
            content: [
                { title: "Mode opératoire", description: "La phase préopératoire peut nécessiter plusieurs rendez-vous. La 1ère consultation consiste notamment en un interrogatoire détaillé et un examen clinique avec parfois la prescription d’une échographie.Le Dr Trevidic y détaillera la procédure et sa technique. Il est conseillé également de corriger la surcharge pondérale avant le geste opératoire. L’excédent de peau supprimé dans la région située entre l’ombilic et le pubis, sera déterminé par un tracé adapté à chaque cas. La peau est retendue vers le bas et l’ombilic conserve et retrouve sa place d’origine." },
                { title: "Suites opératoires", description: "Le geste opératoire va laisser une cicatrice horizontale très discrète au niveau du pubis. Elle sera facilement cachée par les sous-vêtements ou le maillot de bain. Une seconde cicatrice se situe autour du nombril.L’intervention sous anesthésie générale, en ambulatoire, va durer entre une heure et demie et deux heures. La patiente commencera à marcher le jour même et portera une gaine jour et nuit pendant 2 semaines. La douleur reste modérée.Soleil, bains de mer et sport sont autorisés dès la 3ème semaine. Le résultat ne sera véritablement apprécié qu’au bout d’une année, délai nécessaire à l’atténuation de la cicatrice. *Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        },
        "Cuisse & jambes": {
            mainDescription: "Elles peuvent être traitées par une lipoaspiration simple ou par un lifting de la face interne de cuisses. Le vieillissement ou un amaigrissement conséquent dégradent ostensiblement la peau de l’intérieur des cuisses. Un excès de graisse à ce niveau vient souvent s’y associer. On peut alors envisager d’affiner et de retendre la partie haute de la face interne de la cuisse.",
            content: [
                { title: "Mode opératoire", description: "Pour réduire l’infiltration graisseuse dans ce lifting de la face interne de la cuisse, le Dr Trevidic va utiliser la lipoaspiration tout en s’assurant de redraper correctement la peau. L’intervention dure en moyenne deux heures. La patiente devra garder un pansement compressif composé de bandes élastiques collantes jusqu’au rendez- vous suivant, soit durant 4 ou 5 jours.Il s’agit d’une opération en ambulatoire sous anesthésie générale." },
                { title: "Suites opératoires", description: "Dans les premiers jours après l’intervention, des bleus ou un gonflement associé à des tiraillements peuvent apparaître mais restent très supportables. Cette chirurgie reste peu invalidante mais la cicatrice demande des soins journaliers.Et on s’abstiendra de tout mouvement brutal, surtout en s’asseyant. Pourtant le sport peut être repris au bout de 5 semaines. L’exposition au soleil ne recommencera pas avant 3 mois. Enfin pour apprécier les résultats, il faut attendre entre 6 à 12 mois. Une bonne correction de l’infiltration graisseuse et du relâchement de la peau est alors assurée. *Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        },
        "Lipoaspiration": {
            mainDescription: "De nombreuses parties du corps sont concernées par la lipoaspiration qui va permettre d’éradiquer des surcharges de graisse génétiquement programmées : double menton, bras, mollets, hanches, cuisses, genoux, culotte de cheval …",
            content: [
                { title: "Mode opératoire", description: "Les cellules graisseuses (adipocytes) en surnombre vont être aspirées à l’aide d’une canule mousse qui sera introduite à travers de petites incisions situées discrètement dans un pli naturel. La qualité de la peau va déterminer la quantité de graisse extraite et la réussite de l’intervention.Dans la mesure où ces cellules ne peuvent se multiplier, il n’y aura pas de récidive de cet excédent d’adipocytes.On pratiquera sous anesthésie locale associée à une légère sédation mais aussi parfois sous anesthésie générale, en ambulatoire.La durée du geste opératoire variera en fonction de la quantité de graisse à extraire et du nombre de zones anatomiques. Elle varie entre 30 minutes et 2 heures." },
                { title: "Suites opératoires", description: "L’inconfort reste généralement très supportable, associé souvent à de simples calmants.On attendra en moyenne 3 semaines pour reprendre le sport ou pour s’exposer au soleil.C’est d’ailleurs au bout de 3 semaines que le résultat commence à apparaître, après résorption de l’œdème. Il faudra 3 à 6 mois à la peau pour se rétracter et se réadapter à la nouvelle silhouette. Certaines irrégularités de la peau peuvent persister après la lipoaspiration. La lipoaspiration ne traite pas l’aspect de peau d’orange de la cellulite.*Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        },
        "Bras": {
            mainDescription: "Ils peuvent être traités par une lipoaspiration simple ou par un lifting des bras. Le lifting brachial se situe sur la face interne du bras dont le relâchement traduit la vieillesse ou un amaigrissement important.",
            content: [
                { title: "Mode opératoire", description: "Il s’agit d’un geste sous anesthésie générale et en ambulatoire qui dure environ une heure et demie à deux heures. Une lipoaspiration est souvent pratiquée dans le même temps opératoire." },
                { title: "Suites opératoires", description: "Les suites sont plus gênantes que douloureuses. En effet, la patiente sortira du bloc opératoire avec un pansement compressif qu’elle devra garder jusqu’au rendez-vous suivant, soit 4 ou 5 jours.Les douleurs sont très supportables avec parfois des hématomes peu importants, un léger gonflement ou encore des sensations de tiraillement. Il conviendra pendant cette période d’éviter tout mouvement d’étirement brutal, comme par exemple lever les bras pour ne pas tirer sur les sutures. Le sport peut être repris dès la 4ème semaine après l’intervention et la cicatrice sera protégée du soleil pendant 3 mois. Le résultat final s’appréciera entre 6 à 12 mois après l’opération, l’amélioration de la morphologie du bras étant souvent spectaculaire.*Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        }
    };

    useEffect(() => {
        setContentList(paragraphContent[clickedItem].content);
        setMainDescription(paragraphContent[clickedItem].mainDescription);
    }, [clickedItem]);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const parallaxFactor = 0.04;
    const backgroundStyle: React.CSSProperties = {
        transform: `translate3d(0, ${-scrollPosition * parallaxFactor}px, 0)`,
    };

    return (
        <section className="page-animation">
            <div className="bg-[url('https://www.trevidic.com/wp-content/uploads/2021/08/trevidic-body-1920x4445.jpg')] h-[250vh] bg-cover bg-no-repeat bg-fixed bg-top w-full bg-parallax active" style={backgroundStyle}>
                <div className="absolute inset-0 bg-darkB opacity-30 "></div>
                <h2 className="text-5xl mb-[55rem] flex justify-center items-center sm:text-6xl pt-[25rem] pb-5 text-lightB animate-slidein600">
                    CORPS
                </h2>
                <h1 className={`text-5xl ml-[27.5rem] relative mb-10 text-lightB uppercase ${isTransitioning ? 'transition-opacity duration-300 opacity-0' : 'transition-opacity duration-300 opacity-100'}`}>{clickedItem}</h1>
                <div className="flex">
                    <div className="w-[16rem]">
                        <ul className="leading-[4rem] text-xl relative">
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Ventre" ? "clicked" : ""}`} onClick={() => handleClick("Ventre")}>Ventre</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Cuisse & jambes" ? "clicked" : ""}`} onClick={() => handleClick("Cuisse & jambes")}>Cuisse & jambes</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Lipoaspiration" ? "clicked" : ""}`} onClick={() => handleClick("Lipoaspiration")}>Lipoaspiration</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Bras" ? "clicked" : ""}`} onClick={() => handleClick("Bras")}>Bras</button>
                            </li>
                        </ul>
                    </div>
                    <div className="ml-[12rem] w-[39rem] relative">
                        <div className={`text-lightB text-xl ${isTransitioning ? 'transition-opacity duration-300 opacity-0' : 'transition-opacity duration-300 opacity-100'}`}>
                            <p>{mainDescription}</p>
                        </div>
                        <div className="bg-transparent mt-[3.5rem] text-xl relative w-[60rem]">
                            <CeQuilFautSavoirSection
                                key={componentKey}
                                iconColor="white"
                                inputControl="transparent"
                                light={true}
                                contentList={contentList}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Questions />
        </section>
    );
}

export default Corps;
