import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactSchema } from '../validations/contact.validation';
import axios from 'axios';
import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';



export function ContactForm() {


  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>('success');
  const [message, setMessage] = useState("")



  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>

  );

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
    resolver: yupResolver(ContactSchema)
  });

  const consent = watch('consent', false);

  const onSubmit = async (data: any) => {

    try {
      const response = await axios.post('http://localhost:5000/contact', data)
      if (response.status === 200) {
        setOpen(true)
        setSeverity('success');
        setMessage("Message envoyé avec succée")
      }

    } catch (error) {
      setSeverity('error')
      console.log(severity)
      setOpen(true)
      setMessage("N est pas envoyé")
      console.error(error)
    }
    reset()
  };

  return (

    <div className="flex  flex-col md:flex-row  mx-auto p-4">

      <div className="md:w-1/3  p-4 flex flex-col items-center">
        <h1 className='text-5xl mb-[3vh] mt-[12vh]'>COORDONEÉS</h1>
        <div className="w-full max-w-xs mb-10 mt-10">
          <img src="https://t9015588433.p.clickup-attachments.com/t9015588433/aaef7cfc-aabf-4303-8fb9-2e27bdd002ba/69157607_3376479902365919_2787508425930571776_n.jpg" className="w-full " />
        </div>
        <div className="space-y-4">
          <div className="flex items-start mb-4">
            <div className="flex items-center justify-center w-[5vh] h-[5vh] border border-gray-300 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21" className="text-black fill-current">
                <path d="M13 6.322C13 2.83 10.09 0 6.5 0S0 2.83 0 6.322c0 3.493 2.91 6.322 6.5 6.322V21l5.818-11.848A6.276 6.276 0 0013 6.322z"></path>
              </svg>
            </div>
            <p className="ml-4 mb-5 text-gray-700">
              Dr Youssef Gam,<br />
              Médecine &amp; chirurgie esthétiques<br />

            </p>
          </div>

          <div className="flex items-center ">
            <div className="flex items-center justify-center w-[5vh] h-[5vh] border border-gray-300 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="text-black fill-current">
                <path d="M19.386 15.75a2.108 2.108 0 01-.522 3.36 7.543 7.543 0 01-5.476.642c-4.886-1.23-11.91-8.253-13.14-13.14a7.543 7.543 0 01.643-5.475A2.108 2.108 0 014.25.615l2.085 2.084c.615.61.792 1.535.445 2.328-.186.435-.45.832-.778 1.172-2.384 2.384 5.417 10.185 7.8 7.8.34-.33.737-.595 1.173-.781a2.092 2.092 0 012.328.445z"></path>
              </svg>
            </div>
            <p className="ml-4 text-gray-700">+216 55 725 165</p>
          </div>
        </div>
      </div>

      <div className="md:w-2/3 p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="max-w-xl mx-auto">
          <h1 className="text-5xl mb-10 mt-[10vh] leading-[8vh]">ENVOYEZ-NOUS UN MESSAGE</h1>

          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 pr-2">
              <input
                placeholder="Nom*"
                type="text"
                {...register('name')}
                className="w-full border-b mb-6 text-3xl border-gray-300 focus:border-black outline-none placeholder-black py-1"
              />
              <div className="text-red-500">
                {errors.name && <span>{errors.name.message}</span>}
              </div>
            </div>
            <div className="w-full md:w-1/2 pl-2">
              <input
                placeholder="Prenom*"
                type="text"
                {...register('prenom')}
                className="w-full mb-6 placeholder-black text-3xl border-b border-gray-300 focus:border-black outline-none py-1"
              />
              <div className="text-red-500">
                {errors.prenom && <span>{errors.prenom.message}</span>}
              </div>
            </div>
          </div>

          <input
            placeholder="Email*"
            type="text"
            {...register('email')}
            className="w-full placeholder-black mb-6 text-3xl border-b border-gray-300 focus:border-black outline-none py-1"
          />
          <div className="text-red-500">
            {errors.email && <span>{errors.email.message}</span>}
          </div>

          <input
            placeholder="Telephone*"
            type="text"
            {...register('phoneNumber')}
            className="w-full border-b mb-6 placeholder-black text-3xl border-gray-300 focus:border-black outline-none py-1"
          />
          <div className="text-red-500">
            {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
          </div>

          <input
            placeholder="Sujet*"
            type="text"
            {...register('sujet')}
            className="w-full border-b mb-6 placeholder-black text-3xl border-gray-300 focus:border-black outline-none py-1"
          />
          <div className="text-red-500">
            {errors.sujet && <span>{errors.sujet.message}</span>}
          </div>

          <textarea
            placeholder="Message*"
            {...register('message')}
            className="w-full placeholder-black mb-6 text-3xl focus:outline-none py-6 px-6 h-32 bg-transparent border border-black mt-7"
          />
          <div className="text-red-500">
            {errors.message && <span>{errors.message.message}</span>}
          </div>

          <div className="mt-4 flex items-start">
            <input
              type="checkbox"
              {...register('consent')}
              className="mr-2 mt-1"
            />
            <label htmlFor="consent" className="text-black">
              En cochant cette case, j’affirme avoir pris connaissance de la politique de confidentialité*
            </label>
          </div>
          <div className="text-red-500">
            {errors.consent && <span>{errors.consent.message}</span>}
          </div>

          <p className="text-sm mt-2">*Champs obligatoires</p>
          <button
            type="submit"
            className={`mt-6 mb-6 px-[23vh] py-3 transition-colors duration-300 text-2xl ${consent ? "text-black" : "text-consent"
              }  underline`}
          >
            ENVOYER
          </button>
        </form>
        <Snackbar

          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          action={action}
        >
          <Alert onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>



    </div>
  );
}
