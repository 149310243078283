import React, { useEffect, useState, useRef } from "react";
import SurgerySection from "./SurgerySection";

const HeroSection: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setScrollPosition(window.scrollY);
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const parallaxOffset = Math.min(scrollPosition * 0.4, 500); // Cap the parallax effect

  return (
    <section>
      <div
        style={{
          transform: `translate3d(0, ${-parallaxOffset}px, 0)`,
          willChange: "transform",
        }}
        className="absolute inset-0  bg-[url('https://www.trevidic.com/wp-content/uploads/2021/07/trevidic-home-hero-1920x1282.jpg')] sm:h-[200vh] h-[200vh] bg-cover bg-no-repeat   bg-top w-full  active"
      >
        <div className="absolute inset-0 bg-darkB opacity-30 "></div>
      </div>

      <div className="z-10 relative flex flex-col justify-center items-center text-center">
        <h2 className="sm:text-5xl text-3xl sm:pt-52 pt-32 pb-5 text-lightB animate-slidein600 ">
          CHIRURGIEN ESTHÉTIQUE, TUNIS
        </h2>
        <h2 className="sm:text-5xl text-3xl text-lightB pb-5 sm:mb-[20vh] mb-16 animate-slidein600">
          DR. YOUSSEF GAM
        </h2>
        {/* <div className="w-full">
          <GptInputField />
        </div> */}
        {/* <div className={`mb-[27vh] ${buttonVisible ? "animate-slidein600" : "opacity-0"} text-xl text-lightB`}>
          <Link to={"/contact"}>
            <button className="white_button">Contactez-Nous</button>
          </Link>
        </div> */}
        <div
          ref={textContainerRef}
          className={`sm:flex sm:px-[4rem]  sm:pt-20 sm:text-container transition-opacity duration-500 ${"animate-slidein600 opacity-100"}`}
        >
          <p className="text-lightB sm:text-5xl sm:flex-auto pr-24 text-left text-4xl sm:mb-24 mb-5 sm:text-left">
            BIOGRAPHIE
          </p>
          <p className="text-2xl sm:mb-[20vh] mb-24 text-lightB  first-letter:text-9xl first-letter:font-primaryLight first-letter:mr-3 first-letter:float-left text-left font-textDescription">
            Le docteur Youssef est un chirurgien spécialiste en chirurgie
            plastique, reconstructrice et esthétique. Il fait partie des
            chirurgiens qualifiés et reconnus par le Conseil National de l’Ordre
            des Médecins. Il est chef de service de chirurgie plastique et
            reconstructrice à l’hôpital Sainte-Anne à Paris. Il intervient
            régulièrement dans de nombreux congrès internationaux. Il a rédigé
            plus d’une soixantaine d’articles dans différentes revues et
            journaux scientifiques aux États-Unis et en Europe. Il enseigne dans
            plusieurs écoles médicales et chirurgicales, françaises et
            étrangères. Il dirige de nombreuses études cliniques.
          </p>
        </div>
        <div>
          <h2 className="sm:text-6xl text-4xl text-lightB sm:pb-24 pb-16">
            CHIRURGIE ESTHÉTIQUE
          </h2>
        </div>

        <div className="mb-[10vh]">
          <SurgerySection />
        </div>
      </div>
      <div className="text-center justify-center"></div>
    </section>
  );
};

export default HeroSection;
