import React from "react";

interface ButtonProps {
  text: string;
}

const Button: React.FC<ButtonProps> = ({ text }) => {
  return (

    <div className="font-primaryMedium">
      <button className="white_button" >
        {text}
      </button>
    </div>

  );
};

export default Button;
