import Framer from "./MovingText";
import FramerReverse from "./ReverseMovingText";
import ImageBannerAnimation from "./ImageBannerAnimation";

interface Treatment {
  title: string;
  imageUrl: string;
  buttonText: string;
  variant: 'light' | 'dark' | 'violet';
  layout: 'standard' | 'reverse';
  multiline?: boolean;
}

const treatments: Treatment[] = [
  {
    title: "INJECTION D'ACIDE HYALURONIQUE",
    imageUrl: "https://www.trevidic.com/wp-content/uploads/2021/07/trevidic-contact.jpg",
    buttonText: "DEVOUVRIR",
    variant: 'light',
    layout: 'standard'
  },
  {
    title: "INJECTION DE TOXINE BOTULIQUE",
    imageUrl: "",
    buttonText: "DÉCOUVRIR",
    variant: 'dark',
    layout: 'standard',
    multiline: true
  },
  {
    title: "MÉSOTHERAPY",
    imageUrl: "https://www.trevidic.com/wp-content/uploads/2021/07/trevidic-mesotherapy.jpg",
    buttonText: "DÉCOUVRIR",
    variant: 'dark',
    layout: 'standard'
  },
  {
    title: "PEELING",
    imageUrl: "https://www.trevidic.com/wp-content/uploads/2021/07/trevidic-peeling.jpg",
    buttonText: "DÉCOUVRIR",
    variant: 'violet',
    layout: 'reverse'
  }
];

const TreatmentSection = ({ treatment, className = "" }: { treatment: Treatment, className?: string }) => {
  const getVariantStyles = () => {
    switch (treatment.variant) {
      case 'dark':
        return 'bg-darkB text-lightB';
      case 'light':
        return 'bg-[#f8f5f2] text-black';
      case 'violet':
        return 'bg-lightViolet';
    }
  };

  if (treatment.layout === 'reverse') {
    return (
      <div className={`sm:px-10 py-[9rem] px-[1rem] sm:mt-0 mt-[2rem] my-[1rem] sm:my-0 sm:py-10 ${className}`}>
        <h1 className="text-darkB mb-11 mx-[0.3] text-5xl">{treatment.title}</h1>
        <div className="text-darkB mx-[0.3] sm:mt-[17vh]">
          <button className="black_button sm:mt-0 mt-[5rem]">{treatment.buttonText}</button>
        </div>
      </div>
    );
  }

  if (treatment.title === "MÉSOTHERAPY") {
    return (
      <div className={className}>
        <h1 className="text-lightB flex h-52 sm:h-0 mx-4 sm:justify-center items-center sm:mt-[8vh] text-5xl">
          {treatment.title}
        </h1>
        <div className="text-lightB sm:text-left mx-4 sm:mb-0 mb-10 sm:my-[18vh] sm:mx-[35vh]">
          <button className="white_button">{treatment.buttonText}</button>
        </div>
      </div>
    );
  }

  return (
    <div className={`flex flex-col p-4 ${getVariantStyles()} ${className} relative`}>
      <h2 className="text-5xl text-left mt-20 mb-[24vh]">
        {treatment.multiline ? (
          treatment.title.split(' ').map((word, i) => (
            <span key={i}>
              {word}
              <br />
            </span>
          ))
        ) : (
          treatment.title
        )}
      </h2>
      <div className="absolute bottom-4 left-4">
        <button className={`${treatment.variant === 'dark' ? 'white_button' : 'black_button'} ${treatment.variant === 'light' ? 'w-[5.5rem]' : ''}`}>
          {treatment.buttonText}
        </button>
      </div>
    </div>
  );
};

export default function MedicineSection() {
  return (
    <section className="bg-white z-30">
      <h1 className="text-center text-3xl sm:text-5xl mb-[10vh]">
        MÉDECINE ESTHÉTIQUE
      </h1>
      
      <div className="border-y border-black py-[0.1rem] sm:py-[0.5rem]">
        <Framer />
      </div>

      <div className="flex flex-col sm:flex-row justify-center items-center">
        <TreatmentSection 
          treatment={treatments[0]} 
          className="w-full h-[500px]"
        />
        <div className="w-full md:h-[500px] overflow-hidden">
          <ImageBannerAnimation imageUrl={treatments[0].imageUrl} />
        </div>
        <TreatmentSection 
          treatment={treatments[1]} 
          className="w-full sm:h-[500px] h-[450px]"
        />
      </div>

      <div className="border-y border-black py-[0.1rem] sm:py-[0.5rem]">
        <FramerReverse />
      </div>

      <div className="flex flex-col sm:h-[33vh] h-[54vh] sm:flex-row">
        <div className="bg-darkB w-full sm:w-[98rem]">
          <TreatmentSection treatment={treatments[2]} />
        </div>
        <div className="sm:overflow-hidden mt-0 sm:mt-0 sm:w-auto">
          <ImageBannerAnimation imageUrl={treatments[2].imageUrl} />
        </div>
      </div>

      <div className="flex flex-col-reverse sm:h-[35vh] sm:mb-10 sm:flex-row">
        <div className="sm:overflow-hidden mt-[-6rem] sm:mt-0 sm:mb-0 w-full sm:w-auto">
          <ImageBannerAnimation imageUrl={treatments[3].imageUrl} />
        </div>
        <div className="bg-lightViolet sm:w-[120rem]">
          <TreatmentSection treatment={treatments[3]} />
        </div>
      </div>
    </section>
  );
}
