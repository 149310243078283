import { useState, useEffect } from "react";
import Questions from "../../components/Questions";
import CeQuilFautSavoirSection from "../../components/CeQuilFautSavoirSection";

const Seins: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [clickedItem, setClickedItem] = useState<string>("Plastie daugmentation");
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [contentList, setContentList] = useState<{ title: string; description: string }[]>([]);
  const [mainDescription, setMainDescription] = useState<string>("");
  const [componentKey, setComponentKey] = useState<number>(0);

  const handleClick = (item: string) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setClickedItem(item === clickedItem ? "Plastie daugmentation" : item);
      setComponentKey((prevKey) => prevKey + 1);
      setIsTransitioning(false);
    }, 300);
  };

  const paragraphContent: {
    [key: string]: {
      mainDescription: string;
      content: { title: string; description: string }[];
    };
  } = {
    "Plastie daugmentation": {
      mainDescription:
        "L'hypoplasie mammaire se caractérise par un volume insuffisant des seins par rapport à la morphologie de la patiente, résultant soit d'un développement glandulaire insuffisant à la puberté, soit de pertes ultérieures de volume suite à la grossesse, à l'allaitement, à l'amaigrissement ou à des déséquilibres hormonaux.",
      content: [
        {
          title: "Mode opératoire",
          description:
            "La mammoplastie consiste à augmenter la taille des seins en insérant des prothèses pré-remplies de gel de silicone, choisies lors de la consultation avec le Dr Trevidic et après des essais, à travers différentes incisions selon les caractéristiques anatomiques de la patiente, soit au niveau du segment inférieur de la circonférence de l’aréole, soit au niveau du sillon sous-mammaire, avec deux positionnements possibles des prothèses par rapport aux muscles pectoraux, pré-musculaire ou rétro-musculaire, déterminés en consultation, et réalisée sous anesthésie générale en une heure.",
        },
        {
          title: "Suites opératoires",
          description:
            "Après la chirurgie, la patiente portera un pansement modelant pendant 5 jours, suivi d'un soutien-gorge médical pendant 2 semaines, avec possibilité de douleur et de gêne initiales nécessitant un traitement antalgique, tandis que le retour aux activités normales, y compris le soleil, les bains de mer et le sport, est possible après 2 semaines, avec un délai de 2 à 3 mois pour apprécier pleinement les résultats. En cas de ptose mammaire, une réduction de l'enveloppe cutanée peut être associée, et lors de la consultation, le Dr. Trevidic présentera plusieurs cas cliniques Avant-Après.",
        },
        {
          title: "Question diverses",
          description:
            "Il est recommandé d'attendre au moins 6 mois après la pose des prothèses mammaires avant d'envisager une grossesse, bien que l'allaitement soit généralement possible selon la patiente, et une augmentation mammaire est envisageable 6 mois après une grossesse ou un allaitement. L'implantation de prothèses mammaires n'augmente pas le risque de cancer du sein et n'interfère pas avec les examens radiologiques, mais il est crucial de signaler systématiquement leur présence. Le Dr. Trevidic utilise exclusivement des implants lisses.",
        },
      ],
    },
    "Ptose mammaire": {
      mainDescription:
        "De nombreuses parties du Seins sont concernées par la lipoaspiration qui va permettre d’éradiquer des surcharges de graisse génétiquement programmées : double menton, bras, mollets, hanches, cuisses, genoux, culotte de cheval …",
      content: [
        {
          title: "Mode opératoire",
          description:
            "Les cellules graisseuses (adipocytes) en surnombre vont être aspirées à l’aide d’une canule mousse qui sera introduite à travers de petites incisions situées discrètement dans un pli naturel. La qualité de la peau va déterminer la quantité de graisse extraite et la réussite de l’intervention.Dans la mesure où ces cellules ne peuvent se multiplier, il n’y aura pas de récidive de cet excédent d’adipocytes.On pratiquera sous anesthésie locale associée à une légère sédation mais aussi parfois sous anesthésie générale, en ambulatoire.La durée du geste opératoire variera en fonction de la quantité de graisse à extraire et du nombre de zones anatomiques. Elle varie entre 30 minutes et 2 heures.",
        },
      ],
    },
    Gynécomastie: {
      mainDescription:
        "La gynécomastie se caractérise par une augmentation du volume de la glande mammaire chez l'homme, souvent bilatérale et symétrique, de consistance ferme et sensible à la palpation, à distinguer des adipomasties, plus fréquentes et correspondant à une accumulation locale de graisse, molle et insensible. L'adipo-gynécomastie est une combinaison des deux. Bien que souvent idiopathique, elle peut parfois être associée à une pathologie hormonale ou à la prise de certains médicaments.",
      content: [
        {
          title: "Mode opératoire",
          description:
            "Il s’agit d’un geste sous anesthésie générale et en ambulatoire qui dure environ une heure et demie à deux heures. Une lipoaspiration est souvent pratiquée dans le même temps opératoire.",
        },
        {
          title: "Les suites opératoires ",
          description:
            "Les suites opératoires peuvent entraîner une légère douleur initiale, traitée avec un antalgique adapté, avec fréquents gonflements, bleus et gêne à l’élévation des bras, et une altération temporaire de la sensibilité pendant quelques mois. L'amélioration est souvent notable et immédiate, mais le résultat définitif est apprécié après quatre à six mois. Le Dr. Trevidic présente plusieurs cas cliniques Avant-Après lors de la consultation.",
        },
      ],
    },
  };

  useEffect(() => {
    setContentList(paragraphContent[clickedItem].content);
    setMainDescription(paragraphContent[clickedItem].mainDescription);
  }, [clickedItem]);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const parallaxFactor = 0.04;
  const backgroundStyle = {
    transform: `translate3d(0, ${-scrollPosition * parallaxFactor}px, 0)`,
  };

  return (
    <section className="relative w-full overflow-hidden">
      <div
        className="sm:h-[220vh] h-[210vh] bg-cover bg-no-repeat bg-fixed bg-top w-full"
        style={{
          ...backgroundStyle,
          backgroundImage: "url('https://www.trevidic.com/wp-content/uploads/2021/10/MicrosoftTeams-image-2.jpg')"
        }}
      >
        <div className="absolute inset-0 bg-darkB opacity-30"></div>
        
        <div className="relative container mx-auto px-4 sm:px-6 max-w-7xl">
          <div className="py-32">
            <h2 className="text-5xl sm:text-6xl py-10 sm:py-20 leading-relaxed text-center text-lightB animate-slidein600 font-semibold">
              CHIRUGIE ESTHÉTIQUE SEINS
            </h2>
            
            <div className="max-w-4xl mx-auto space-y-6">
              <p className="text-lightB text-lg sm:text-xl lg:text-2xl text-center leading-relaxed animate-slidein600">
                La mammoplastie ou chirurgie des seins constitue l'une des premières demandes de chirurgie esthétique des patientes en France. On distingue 3 types de prise en charge :
              </p>
              
              <ul className="text-lightB text-lg sm:text-xl lg:text-2xl leading-relaxed  animate-slidein600 list-disc pl-8">
                <li>le sein trop petit ou hypoplasie mammaire</li>
                <li>le sein trop gros ou hypertrophie mammaire</li>
                <li>le sein qui tombe ou ptose mammaire</li>
              </ul>
              
              <p className="text-lightB text-lg sm:text-xl lg:text-2xl text-center leading-relaxed  animate-slidein600 mb-16 sm:mb-32">
                Ces éléments peuvent s'associer. En effet, une hypertrophie mammaire peut être associée à une ptose mammaire.
              </p>
            </div>
          </div>

          <div className="max-w-9xl mx-auto mt-24">
            <h1 className={`text-3xl sm:text-5xl  text-center  py-20 text-lightB uppercase transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
              {clickedItem}
            </h1>

            <div className="flex flex-col lg:flex-row gap-8">
              <div className="w-full lg:w-96 lg:flex-shrink-0">
                <nav className="flex flex-row lg:flex-col flex-wrap gap-4  justify-center lg:justify-start">
                  {["Plastie daugmentation", "Ptose mammaire", "Gynécomastie"].map((item) => (
                    <button
                      key={item}
                      className={`hr-lines text-lightB focus:outline-none w-full text-left text-lg sm:text-xl transition-colors duration-200 hover:text-gray-300 ${
                        clickedItem === item ? "clicked font-bold" : ""
                      }`}
                      onClick={() => handleClick(item)}
                    >
                      {item.replace("daugmentation", "d'augmentation")}
                    </button>
                  ))}
                </nav>
              </div>

              <div className="flex-1 lg:max-w-3xl">
                <div className={`transition-opacity duration-300 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
                  <p className="text-lightB text-base sm:text-lg lg:text-xl mb-8">
                    {mainDescription}
                  </p>
                  
                  <div className="mt-8">
                    <CeQuilFautSavoirSection
                      key={componentKey}
                      iconColor="white"
                      inputControl="transparent"
                      light={true}
                      contentList={contentList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Questions />
    </section>
  );
};
export default Seins;