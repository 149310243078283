import { useState } from "react";
import { axiosInstance } from "../../constants";

interface Contact {
    _id: string;
    consent: boolean;
    name: string;
    email: string;
    phoneNumber: string;
    sujet: string;
    message: string;
}

interface Appointment {
    _id: string;
    FullName: string;
    appointmentDate: string;
    phoneNumber: number;
}

const Admin = () => {
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [showContacts, setShowContacts] = useState(false);
    const [showAppointments, setShowAppointments] = useState(false);

    const fetchContacts = async () => {
        try {
            const response = await axiosInstance.get<Contact[]>("/contact");
            if (response.data) {
                setContacts(response.data);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    const fetchAppointments = async () => {
        try {
            const response = await axiosInstance.get<Appointment[]>("/appointment");
            if (response.data) {
                setAppointments(response.data);
            }
        } catch (error) {
            console.error("Error fetching appointments:", error);
        }
    };

    const handleShowContacts = async () => {
        await fetchContacts();
        setShowContacts(true);
        setShowAppointments(false);
    };

    const handleShowAppointments = async () => {
        await fetchAppointments();
        setShowContacts(false);
        setShowAppointments(true);
    };

    return (
        <div className="p-6 bg-gray100 min-h-screen">
            <h2 className="text-2xl mt-20 font-bold mb-4">Admin Dashboard</h2>
            <div className="mb-4">
                <button onClick={handleShowContacts} className="mr-2 px-4 py-2 bg-gray800 text-lightB rounded">
                    Show Contacts
                </button>
                <button onClick={handleShowAppointments} className="px-4 py-2 bg-gray800 text-lightB rounded">
                    Show Appointments
                </button>
            </div>

            {showContacts && (
                <div className="overflow-x-auto">
                    <h2 className="text-2xl mt-8 font-bold mb-4">Contact Dashboard</h2>
                    <table className="min-w-full bg-lightB shadow-md rounded-lg">
                        <thead className="bg-gray800 text-lightB">
                            <tr>
                                <th className="py-3 px-4 text-left">Name</th>
                                <th className="py-3 px-4 text-left">Email</th>
                                <th className="py-3 px-4 text-left">Phone Number</th>
                                <th className="py-3 px-4 text-left">Subject</th>
                                <th className="py-3 px-4 text-left">Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((contact) => (
                                <tr key={contact._id} className="border-b hover:bg-gray100">
                                    <td className="py-3 px-4">{contact.name}</td>
                                    <td className="py-3 px-4">{contact.email}</td>
                                    <td className="py-3 px-4">{contact.phoneNumber}</td>
                                    <td className="py-3 px-4">{contact.sujet}</td>
                                    <td className="py-3 px-4">{contact.message}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {showAppointments && (
                <div className="overflow-x-auto">
                    <h2 className="text-2xl mt-8 font-bold mb-4">Appointment Dashboard</h2>
                    <table className="min-w-full bg-lightB shadow-md rounded-lg">
                        <thead className="bg-gray800 text-lightB">
                            <tr>
                                <th className="py-3 px-4 text-left">Full Name</th>
                                <th className="py-3 px-4 text-left">Appointment Date</th>
                                <th className="py-3 px-4 text-left">Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appointments.map((appointment) => (
                                <tr key={appointment._id} className="border-b hover:bg-gray100">
                                    <td className="py-3 px-4">{appointment.FullName}</td>
                                    <td className="py-3 px-4">{appointment.appointmentDate}</td>
                                    <td className="py-3 px-4">{appointment.phoneNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Admin;
