import { Link } from "react-router-dom";
import ImageBannerAnimation from "./ImageBannerAnimation";
import SandClock from "./SandClock";

function Questions() {
  return (
    <div className="relative flex flex-col sm:flex-row mt-[-6rem] mb-[15vh] h-auto sm:h-[70vh]">
      <div className="bg-lightBlue w-full">
        <h1 className="text-darkB sm:text-center text-5xl mx-5 mt-[13vh] sm:mt-[10vh]">
          DES QUESTION ?
        </h1>
        <div className="flex justify-center mr-24 mt-10 -mb-5 sm:mt-24 ">
          <SandClock />
        </div>
        <div className=" text-center">
          <Link to="/contact">
            <button className="black_button text-xl mb-14 mt-14 sm:mt-[8rem] sm:mx-[18rem]">
              Contactez-Nous
            </button>
          </Link>
        </div>
      </div>
      <div className="overflow-hidden w-full sm:w-[50%]">
        <ImageBannerAnimation imageUrl="https://www.trevidic.com/wp-content/uploads/2021/09/Contact-min.png" />
      </div>
    </div>
  );
}

export default Questions;
