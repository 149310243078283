import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import ContactPage from "./pages/contactPage/contact.page";
import PageContainer from "./layouts/pageContainer";
import Login from "./pages/loginPage/login";
import Admin from "./pages/AdminDashboard/admin";
import { AuthProvider } from "./context/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import Corps from "./pages/Corps/corps";
import ScrollToTop from "./components/ScrollToTop";
import AcideHyaluronique from "./pages/AcideHyaluroniquePage/acideHyaluronique";
import Seins from "./pages/Seins/Seins";
import Visage from "./pages/Visage/Visage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<PageContainer />}>
            <Route index element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/corps" element={<Corps />} />
            <Route path="/seins" element={<Seins />} />
            <Route path="/visage" element={<Visage />} />
            <Route path="/acide-hyaluronique" element={<AcideHyaluronique />} />

          </Route>

          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={<ProtectedRoute element={<Admin />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
