import { useState, useEffect } from "react";
import Questions from "../../components/Questions";
import CeQuilFautSavoirSection from "../../components/CeQuilFautSavoirSection";

const Visage: React.FC = () => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [clickedItem, setClickedItem] = useState<string>("Lipostructure");
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const [contentList, setContentList] = useState<{ title: string; description: string }[]>([]);
    const [mainDescription, setMainDescription] = useState<string>("");
    const [componentKey, setComponentKey] = useState<number>(0);

    const handleClick = (item: string) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setClickedItem(item === clickedItem ? 'Lipostructure' : item);
            setComponentKey(prevKey => prevKey + 1);
            setIsTransitioning(false);
        }, 300);
    };

    const paragraphContent: { [key: string]: { mainDescription: string; content: { title: string; description: string }[] } } = {
        "Lipostructure": {
            mainDescription: "Le transfert de graisse autologue ou lipostructure est aussi connu sous le nom de greffe graisseuse, lipofilling ou lipomodelage.",
            content: [
                { title: "Mode opératoire", description: "Une anesthésie locale associée à une sédation ou une anesthésie générale sera privilégiée en fonction de la taille de la zone à traiter. Le prélèvement dans la zone graisseuse se pratique à l’aide d’une très fine canule d’aspiration. Ce tissu sera centrifugé et filtré. On peut alors séparer les cellules graisseuses intactes (adipocytes), des éléments non exploitables. La réinjection se fait à l’aide de micro-canules pour laisser des cicatrices minimales. La durée de l’intervention peut varier de 30 minutes à une heure et demie. Elle sera réalisée en ambulatoire et parfois associée à une autre intervention, comme le lifting cervico-facial ou la pose de prothèses mammaires." },
                { title: "Suites opératoires", description: "Un inconfort tout-à-fait supportable et des bleus ou un œdème qui se résorbent dans un délai de 10 à 15 jours. Pas d ‘exposition au soleil avant au moins 2 semaines. Et un résultat visible 2 à 3 semaines après l’intervention même s’il ne sera définitif qu’au bout de 2 mois, avec notamment une amélioration de la texture et de l’élasticité de la peau. Parfois un deuxième temps opératoire est nécessaire en cas de pertes importantes de volume. *Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        },
        "Cou et ovale du visage": {
            mainDescription: "Le cou commence à se relâcher dès l’âge de 40 ans et la chirurgie du cou permet de corriger ce relâchement cutané, associé ou non à un excédent graisseux sous le menton et dans les bajoues. Dans le cas d’un double menton isolé et si la peau à cet endroit est de bonne qualité, c’est à dire élastique, le chirurgien peut pratiquer une simple Oreilles qui permettra une rétractation harmonieuse. Une anesthésie locale et une sédation sont alors proposées en ambulatoire. Mais s’il s’agit d’un relâchement cutané important, un lifting cervico-facial est nécessaire pour rajeunir à la fois le cou, l’ovale et les joues.",
            content: [
                { title: "Mode opératoire", description: "Il s’agit d’une chirurgie ambulatoire sous anesthésie générale qui dure entre 1h30 et 2h30 selon l’importance des gestes et des corrections apportées. Les cicatrices très discrètes se situent dans le cuir chevelu, puis à l’intérieur et derrière l’oreille, placées dans les plis naturels. Enfin, elles se situent aussi au niveau du cuir chevelu postérieur. Cette intervention va permettre de remettre en tension les muscles et les structures sous la peau. De plus, la peau va donc être redrapée à la demande sur les nouveaux galbes, grâce à un geste chirurgical efficace, subtil et sans excès. La peau suit alors, sans traction superflue, la remise en tension du « sous-sol ». Le résultat sera à la fois naturel et durable. En effet, seule une chirurgie bien menée va réussir à corriger le relâchement et annuler la souffrance psychique qui en découle… Dans le cas où des surcharges graisseuses sont à corriger, elles seront traitées en même temps par Oreilles. Et si, au contraire, le visage est émacié, une injection de graisse viendra y remédier." },
                { title: "Suites opératoires", description: "Le patient sort du bloc avec un pansement autour de la tête qui devra être gardé pendant 3 jours, jusqu’au rendez-vous de contrôle. L’application de froid, le traitement homéopathique à base d’arnica et la position tête relevée lors du sommeil, pendant les 5 premiers jours post-opératoires, évitent et diminuent les hématomes et l’inflammation. Ces ecchymoses et ces œdèmes disparaissent en moyenne dans les 10 jours post-opératoires. Les 10 premiers jours, il faut se reposer au maximum et éviter tout effort violent. Il faut s’interdire l’exposition solaire pendant 2 semaines ainsi que le sport intense. Au bout d’un à trois mois, on peut avoir une bonne idée du résultat définitif. Mais les cicatrices sont encore un peu rosées et indurées et ne s’atténuent que vers le sixième mois. Même si les complications de cette opération sont rares, il faut être informé qu’un hématome, une souffrance cutanée, une lésion nerveuse ou des altérations de la sensibilité peuvent survenir." }
            ]
        },
        "Oreilles": {
            mainDescription: "L’otoplastie ou chirurgie des oreilles décollées va venir corriger la forme et la position des pavillons auriculaires. Elle s’adresse à des patients qui jugent leur taille et leur aspect trop visibles.  Il s’agit parfois de ne corriger qu’une seule oreille, en cas d’asymétrie.",
            content: [
                { title: "Mode opératoire", description: "L’intervention se réalise au bloc opératoire sous anesthésie locale avec une sédation. L’incision, dans un pli naturel derrière l’oreille reste très discrète sans coupe de cheveux. L’opération dure entre 30 et 40 minutes, sans hospitalisation." },
                { title: "Suites opératoires", description: "Un bandage élastique à porter pendant 24 heures à la sortie du bloc sera remplacé par un bandeau de tennis pour 8 jours. La douleur est tout à fait supportable et les premiers résultats sont visibles immédiatement, même si les oreilles peuvent parfois être gonflées et présenter quelques bleus. Mais pour apprécier le résultat final, il faudra attendre entre 1 à 2 mois, selon le rythme de la cicatrisation du patient. Pendant un mois, on évitera les activités physiques ou sportives avec risque de contact. La sensibilité des oreilles, légèrement atténuée au début, exclut l’exposition au grand froid. *Lors de votre consultation, le Docteur Trevidic vous montrera de nombreux cas cliniques Avant-Après" }
            ]
        },
        "Paupières": {
            mainDescription: "La blépharoplastie ou chirurgie esthétique des paupières s’adresse surtout à des patients, hommes ou femmes, dont les paupières ont tendance à s’affaisser avec le temps. Ce sera une intervention de la maturité. Mais elle peut aussi concerner un défaut héréditaire comme des poches sous les yeux. Le praticien peut intervenir soit sur les deux paupières supérieures ou inférieures, soit sur les quatre paupières à la fois.",
            content: [
                { title: "Mode opératoire", description: "S’il s’agit des paupières supérieures, une anesthésie locale associée à une sédation suffira. En revanche, pour la chirurgie des paupières inférieures ou celle des 4 paupières, il faut une courte anesthésie générale. L’intervention peut durer de 30 minutes à 1 heure et demie selon l’importance du geste et se pratique en ambulatoire. Les incisions sont dissimulées dans les plis naturels et les fils de sutures très fins se retirent au bout de 5 à 6 jours. L’intervention sur les poches isolées des paupières inférieures ne laissera aucune cicatrice, les incisions s’effectuant à l’intérieur des paupières. De petites bandes autoadhésives sur la peau des paupières opérées constituent le pansement final qu’il faudra garder pendant quelques jours." },
                { title: "Suites opératoires", description: "Un traitement homéopathique sera prescrit. Il faut éviter tout effort violent et ne pas pencher la tête en avant, afin de diminuer le risque d’hématomes. En revanche, on peut se doucher dès le premier soir et porter des lunettes de soleil à la sortie de la clinique. Pas de véritables douleurs mais quelquefois de l’inconfort et une sensation de tension au niveau des paupières. Pour apprécier les résultats, il faut patienter entre 3 et 6 mois : c’est le temps nécessaire aux tissus pour retrouver leur souplesse et estomper les cicatrices." }
            ]
        },
        "Nez": {
            mainDescription: "La rhinoplastie est une intervention chirurgicale qui modifie la forme du nez dans un but esthétique. Elle peut toutefois corriger également des problèmes de respiration nasale dus à la déviation de la cloison. Elle se propose donc d’améliorer les disgrâces du nez en supprimant la bosse du dos du nez, en remodelant la pointe et les narines ou encore en rectifiant l’angle entre le nez et la lèvre supérieure. Il peut s’agir également de remédier à des déformations dues à un accident.",
            content: [
                { title: "Mode opératoire", description: "Réalisée sous anesthésie générale, la rhinoplastie dure environ une heure, en ambulatoire. Les incisions sont réalisées le plus souvent à l’intérieur du nez, parfois à l’extérieur au niveau de la columelle. Une attelle de maintien en plâtre protégera le nez du patient à la sortie du bloc opératoire. Elle peut parfois remonter sur le front et sera gardée pendant une semaine." },
                { title: "Suites opératoires", description: "Le gonflement peut persister quelques jours, les ecchymoses autour des yeux sont le plus souvent minimes. Le repos, l’absence de soleil et de tabac sont au programme des 2 premières semaines. Il faudra attendre 1 mois pour s’adonner au sport ou à des activités sportives violentes. C’est le temps nécessaire également pour apprécier le résultat tout en sachant que 6 mois à 1 an de lente et subtile évolution sont le délai nécessaire pour l’aspect définitif." }
            ]
        },
        "Menton": {
            mainDescription: "La mentoplastie ou génioplastie est une chirurgie qui permet de diminuer ou d’augmenter la taille du menton, mais qui peut servir aussi à modifier sa forme ou sa position. Dans le cas d’une augmentation, on peut avancer le menton osseux à l’aide soit d’une prothèse en silicone dur, soit d’injections localisées de la graisse du patient ou d’acide hyaluronique.",
            content: [
                { title: "Mode opératoire", description: "La génioplastie nécessite une anesthésie générale de courte durée pratiquée en ambulatoire. Il n‘y aura aucune cicatrice externe puisque l’incision est faite à l’intérieur de la bouche." },
                { title: "Suites opératoires", description: "On observe parfois un gonflement modéré de la lèvre inférieure ainsi que du cou et une insensibilité locale temporaire. Une excellente hygiène buccale avec des bains de bouche est indispensable." }
            ]
        }

    };

    useEffect(() => {
        setContentList(paragraphContent[clickedItem].content);
        setMainDescription(paragraphContent[clickedItem].mainDescription);
    }, [clickedItem]);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const parallaxFactor = 0.04;
    const backgroundStyle: React.CSSProperties = {
        transform: `translate3d(0, ${-scrollPosition * parallaxFactor}px, 0)`,
    };

    return (
        <section className="page-animation">
            <div className="bg-[url('https://www.trevidic.com/wp-content/uploads/2021/12/iStock-1062396968-min-1920x1440.jpg')] h-[250vh] bg-cover bg-no-repeat bg-fixed bg-top w-full bg-parallax active" style={backgroundStyle}>
                <div className="absolute inset-0 bg-darkB opacity-30 "></div>
                <h2 className="text-6xl mb-[45rem] flex justify-center items-center sm:text-6xl pt-[25rem] pb-5 text-lightB animate-slidein600">
                    CHIRUGIE ESTHÉTIQUE VISAGE
                </h2>
                <h1 className={`text-5xl ml-[31rem] relative mb-10 text-lightB uppercase ${isTransitioning ? 'transition-opacity duration-300 opacity-0' : 'transition-opacity duration-300 opacity-100'}`}>{clickedItem}</h1>
                <div className="flex">
                    <div className="w-[19rem]">
                        <ul className="leading-[4rem] text-xl relative">
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Lipostructure" ? "clicked" : ""}`} onClick={() => handleClick("Lipostructure")}>Lipostructure</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Cou et ovale du visage" ? "clicked" : ""}`} onClick={() => handleClick("Cou et ovale du visage")}>Cou et ovale du visage</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Oreilles" ? "clicked" : ""}`} onClick={() => handleClick("Oreilles")}>Oreilles</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Paupières" ? "clicked" : ""}`} onClick={() => handleClick("Paupières")}>Paupières</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Nez" ? "clicked" : ""}`} onClick={() => handleClick("Nez")}>Nez</button>
                            </li>
                            <li>
                                <button className={`hr-lines text-lightB focus:outline-none  ${clickedItem === "Menton" ? "clicked" : ""}`} onClick={() => handleClick("Menton")}>Menton</button>
                            </li>
                        </ul>

                    </div>
                    <div className="ml-[12rem] w-[39rem] relative">
                        <div className={`text-lightB text-xl ${isTransitioning ? 'transition-opacity duration-300 opacity-0' : 'transition-opacity duration-300 opacity-100'}`}>
                            <p>{mainDescription}</p>
                        </div>
                        <div className="bg-transparent mt-[3.5rem] text-xl relative w-[60rem]">
                            <CeQuilFautSavoirSection
                                key={componentKey}
                                iconColor="white"
                                inputControl="transparent"
                                light={true}
                                contentList={contentList}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Questions />
        </section>
    );
}

export default Visage;
